import React from 'react'

const Footer = () => {
  return (
    <>
      <footer className='footer_part'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6 col-lg-3'>
              <div className='single_footer_part'>
                <h4>About Us</h4>
                <p>The Marinduque Electric Cooperative, Inc. (MARELCO) is a non-stock, non-profit corporation duly organized on March 27, 1973</p>
                <a href='/' className='footer_logo'> <img src='/resources/img/system/footer_logo.png' alt='#' /> </a>
              </div>
            </div>
            <div className='col-sm-6 col-lg-3'>
              <div className='single_footer_part'>
                <h4>Contact Info</h4>
                <p>Address : Ihatub, Boac, Marinduque</p>
                <p>Phone : (042) 332 2266</p>
                <p>Email : marelco_inc@yahoo.com</p>
              </div>
            </div>
            <div className='col-sm-6 col-lg-3'>
              <div className='single_footer_part'>
                <h4>Area Offices</h4>
                <p>Buenavista Office - (042) 754 1601</p>
                <p>Gasan Office - (042) 342 1090</p>
                <p>Boac Office - (042) 332 1934</p>
                <p>Mogpog Office - (042) 332 3222</p>
                <p>Sta. Cruz Office - (042) 332 0919</p>
                <p>Torrijos Office - (042) 754 1510</p>
              </div>
            </div>
            <div className='col-sm-6 col-lg-3'>
              <div className='single_footer_part'>
                <h4>Shortcuts</h4>
                <ul className='list-unstyled'>
                  <li><a href='/about_us/history'>History</a></li>
                  <li><a href='/about_us/mission_vision'>Mission &amp; Vision</a></li>
                  <li><a href='/news_and_updates/power_rates'>Power Rates</a></li>
                  <li><a href='/news_and_updates/generation_charges'>Generation Charges</a></li>
                  <li><a href='/members_consumers_owners/infos_and_safety_tips'>Safety Tips</a></li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-sm-6 col-lg-6'>
              <div className='copyright_text'>
                <p>
                  Copyright ©2019 All rights reserved | This template is made with <i className='fa fa-heart' aria-hidden='true' /> by <a href='https://colorlib.com' target='_blank' rel='noopener noreferrer'>Colorlib</a>
                </p>
              </div>
            </div>
            <div className='col-sm-6 col-lg-6'>
              <div className='footer_icon'>
                <ul className='list-unstyled'>
                  <li><a href='http://www.facebook.com/marelco.ihatub' target='_blank' rel='noopener noreferrer' className='single_social_icon'>Visit MARELCO on facebook <i className='fab fa-facebook-f' /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
