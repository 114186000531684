import React, { useEffect } from 'react'

const ElectricalConnectionApplication = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | Electrical Connection Application'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    <>
      <section className="breadcrumb blog_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb_iner">
                <div className="breadcrumb_iner_item">
                  <h2>Electrical Connection Application</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-history">
      <div className="container box_1170">
        <div className="container-members-consumers-owners">
         <h2 className="members-consumers-owners-title">NEW ELECTRICAL CONNECTION APPLICATION</h2>
         <p className="members-consumers-owners-subtitle">
            MGA HAKBANG NA DAPAT GAWIN NG BAGONG KONSUMER NG MARELCO SA PAGPAPAKABIT NG KURYENTE O “ELECTRICAL CONNECTIONS” 
            SANG-AYON SA PINAIIRAL NA AKTA REPUBLIKA BLG. 7920 O ANG BAGONG “ELECTRICAL ENGINEERING LAW”
         </p>
         <ol className="ordered-list members-consumers-owners-content">
            <li>
               Magpagawa ng Electrical Plan sa Electrical Engineer o Master Electrician.
            </li>
            <li>
               Humingi ng Application Form for Electrical Permit sa tanggapan ng Municipal Building Official sa munisipyong nakasasakop sa inyo.
            </li>
            <li>
               Dalhin ang Electrical Plan at Application Form ng Electrical Permit sa inyong Professional Electrical Engineer ( PEE ).
            </li>
            <li>
               Dalhin ang magkalakip na Electrical Plan at Application Form ng Electrical Permit sa Fire Department ng inyong bayan upang bigyan kayo ng Fire Safety Clearance.
            </li>
            <li>
               Dalhin ang Electrical Plan, Fire Safety Clearance at Application Form for Electrical Permit sa tanggapan ng Municipal Building Official upang aprobahan ang aplikasyon.
            </li>
            <li>
               Pagka-aproba ng aplikasyon, pasimulan ang “pag-iinstalasyon ng linya sa loob ng bahay o “wiring installation”
            </li>
            <li>
               Pagkatapos ng“ Electrical o Housewiring Installation”, ipa-inspeksyon ito sa Electrical Inspector ng Municipal Office
            </li>
            <li>
               I-presenta o ipakita sa MARELCO ang Certificate of Final Electrical Inspection
            </li>
            <br/>
         </ol>
        </div>
      </div>
   </section>

      <section className="container-history">
        <div className="container box_1170">
          <div className="container-electrical-requirement">
            <h4 className="electrical-requirement-title">List of Requirements for Electrical Connection Application</h4>
            <ol className="ordered-list electrical-requirement-content">
            <a href="/resources/files/forms/Checklist of requirements.pdf" target='_blank' rel='noopener noreferrer' className="text-center">Checklist of Requirements <i className="fa fa-download" aria-hidden="true" /></a>
               <li>Recent ID Picture - 2X2 (1 pc both husband and wife, if married)</li>
              <li>Valid ID (any two (2) of the following) 
                <ul>- SSS</ul>
                <ul>- PRC</ul>
                <ul>- Driver's License</ul>
                <ul>- Company ID</ul>
                <ul>- Passport</ul>
                <ul>- TIN</ul>
                <ul>- Voter's ID</ul>
                <ul>- Postal ID</ul>
                <ul>- Senior Citizen's ID</ul>
              </li>
              <li>Community Tax</li>
              <li>Barangay Clearance</li>
              <li>Certification from the Barangay where the dwelling is located</li>
              <li>Marriage Contract (for married applicants)</li>
              <li>Electrical Plan</li>
              <li>Electrical Permit (LGU)</li>
              <li>Certificate of Final Electrical Inspection (LGU)</li>
              <li>Fire Safety Clearance (BFP)</li>
              <li>Pre-Membership Orientation
                <ul>- Certificate of Attendance</ul>
              </li>
            </ol>
          </div>
        </div>
      </section>
    </>
  )

}

export default ElectricalConnectionApplication