//office
import BAO from './office/Boac.png'
import BUAO from './office/Buenavista.png'
import GAO from './office/Gasan.png'
import MAO from './office/Mogpog.png'
import SCAO from './office/Sta. Cruz.png'
import TAO from './office/Torrijos.png'

//History
import Hist_img1 from './img/history/history_image_1.jpg'
import Hist_img2 from './img/history/history_image_2.jpg'
import Hist_img3 from './img/history/history_image_3.jpg'
import Hist_img4 from './img/history/history_image_4.jpg'
import Hist_img5 from './img/history/history_image_5.jpg'

//BOD
import Bod1 from './img/bod/board_of_directors_1.png'
import Bod2 from './img/bod/board_of_directors_2.png'
import Bod3 from './img/bod/board_of_directors_3.png'
import Bod4 from './img/bod/board_of_directors_4.png'
import Bod5 from './img/bod/board_of_directors_5.png'
import Bod6 from './img/bod/board_of_directors_6.png'
import GM from './img/manager/GM.png'
import Blank from './img/bod/board_of_directors_blank.png'

//the management
import CPDman from './img/manager/CPD Manager.png'
import ISDman from './img/manager/ISD Manager.png'
import TSDman from './img/manager/TSD Manager.png'
import FSDman from './img/manager/FSD Manager.png'
import ASDman from './img/manager/ASD Manager.png'
import IADman from './img/manager/IAD Manager.png'
import Blank2 from './img/manager/Blank2.png'

//Videos
import Distribution_system from './vids/Distribution_System.mp4'
import Distribution_process from './vids/Power_Distribution_Process.mp4'
import PIR from './vids/Power_Interruption_Reports.mp4'
import Power_outages from './vids/Power_Outages.mp4'
import pmos_vid from './vids/PMOS.mp4'

//Power Rates
import Power_rate from './img/home/power-rates/rate-update.png'
//Generation Breakdown
import GenBreakdown from './img/home/gen-charge/breakdown_update.png'

//
import Senior_Citizen from './img/home/SCdiscount.png'
import Change_Name from './img/home/ChangeName.png'
import Offices from './img/home/offices.png'
import Crumb from './img/home/crumb.png'

//RA11552
import RA11552 from './img/home/ra11552/ra11552.png'
import pg1 from './img/home/ra11552/1.jpg'
import pg2 from './img/home/ra11552/2.jpg'
import pg3 from './img/home/ra11552/3.jpg'
import pg4 from './img/home/ra11552/4.jpg'
import pg5 from './img/home/ra11552/5.jpg'
import pg6 from './img/home/ra11552/6.jpg'
import pg7 from './img/home/ra11552/7.jpg'
import pg8 from './img/home/ra11552/8.jpg'
import pg9 from './img/home/ra11552/9.jpg'

import bg_journey from './img/bg-journey.jpg'
import marelco_overview from './img/marelco_overview.gif'


//Consumer Tips
import SafetyTips from './tips/safety-tips-1.png'
import TipidTips from './tips/tipid-tips-1.png'

//Payment Partners
import XenPay from './payment-partners/xenpay.png'
import CardBank from './payment-partners/card-bank.png'
import RaquelPawnshop from './payment-partners/raquel-pawnshop.png'
import SimInsurance from './payment-partners/sim-insurance.png'
import Bayad from './payment-partners/bayad.png'
import Maya from './payment-partners/maya.png'

//interruptions
import SPIDec1 from './schedule_interruptions/december2022/121022.jpg'
import SPIDec2 from './schedule_interruptions/december2022/121122.jpg'
import SPIDec3 from './schedule_interruptions/december2022/121222.jpg'

//Job Vacancy
import gmHiring from './img/job_vacancy/gm_vacancy.jpg'
import landpurchase from './img/home/Landpurchase.png'

// eslint-disable-next-line
export default {
    BAO,BUAO,GAO,MAO,SCAO,TAO,
    
    Hist_img1,
    Hist_img2,
    Hist_img3,
    Hist_img4,
    Hist_img5,
    
    Bod1,
    Bod2,
    Bod3,
    Bod4,
    Bod5,
    Bod6,
    GM,
    Blank, Blank2,

    CPDman,ISDman,TSDman,FSDman,ASDman,IADman,

    Distribution_system,
    Distribution_process,
    PIR,
    Power_outages,
    pmos_vid,

    Power_rate,
    GenBreakdown,
    Senior_Citizen,
    Change_Name,
    Offices,
    Crumb,

    RA11552, pg1, pg2, pg3, pg4, pg5, pg6, pg7, pg8, pg9, 
    
    bg_journey, marelco_overview,

   SafetyTips,
   TipidTips,


   XenPay,
   CardBank,
   RaquelPawnshop,
   SimInsurance,
   Bayad,
   Maya,
   
   SPIDec1,
   SPIDec2,
   SPIDec3,

   gmHiring,
   landpurchase
}
