import React, { useEffect } from 'react'
import Assets from '../../assets/_index'


const BoardOfDirectors = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | Home'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    <>
      <div>
        <section className='breadcrumb blog_bg'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='breadcrumb_iner'>
                  <div className='breadcrumb_iner_item'>
                    <h2>Board of Directors</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='apartment_part container-board-of-directors'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4 col-lg-4'>
                <div className='single_appartment_part'>
                  <div className='board-of-directors-image'>
                    <img src={Assets.Bod4} alt='Board of Director' />
                    <div className='board-of-directors-image-content'>
                      <h3>Dir. Christopher M. Morales</h3>
                      <p>President</p>
                      <p>Board of Director (Mogpog District)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='single_appartment_part'>
                  <div className='board-of-directors-image'>
                    <img src={Assets.Bod5} alt='Board of Director' />
                    <div className='board-of-directors-image-content'>
                      <h3>Dir. Glenda M. Mercader</h3>
                      <p>Vice President</p>
                      <p>Board of Director (Sta. Cruz District)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='single_appartment_part'>
                  <div className='board-of-directors-image'>
                    <img src={Assets.Bod3} alt='Board of Director' />
                    <div className='board-of-directors-image-content'>
                      <h3>Dir. Emelina L. Miciano</h3>
                      <p>Secretary</p>
                      <p>Board of Director (Boac District)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
            <div className='col-md-4 col-lg-4 col-offset-md-4'>
                <div className='single_appartment_part'>
                  <div className='board-of-directors-image'>
                    <img src={Assets.Bod2} alt='Board of Director' />
                    <div className='board-of-directors-image-content'>
                      <h3>Dir. Delfin M. Natal, Jr.</h3>
                      <p>Treasurer</p>
                      <p>Board of Director (Gasan District)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-lg-4 col-offset-md-4'>
                <div className='single_appartment_part'>
                  <div className='board-of-directors-image'>
                    <img src={Assets.Bod1} alt='Board of Director' />
                    <div className='board-of-directors-image-content'>
                      <h3>Dir. Noel B. Lontoc</h3>
                      <p>Auditor</p>
                      <p>Board of Director (Buenavista District)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-lg-4 col-offset-md-4'>
                <div className='single_appartment_part'>
                  <div className='board-of-directors-image'>
                    <img src={Assets.Bod6} alt='Board of Director' />
                    <div className='board-of-directors-image-content'>
                      <h3>Dir. Federico P. Larracas</h3>
                      <p>PRO</p>
                      <p>Board of Director (Torrijos District)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )

}


export default BoardOfDirectors
