import React, { useEffect } from 'react'

const WhatsNew = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | What\'s New'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    <>
      <section className='breadcrumb blog_bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='breadcrumb_iner'>
                <div className='breadcrumb_iner_item'>
                  <h2>What's New?</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='contact-section area-padding'>
        <div className='container'>
          <div className='text-center mb-3'>
            <button className='btn' type='button' data-toggle='collapse' data-target='public-bidding-2024' aria-expanded='false' aria-controls='collapseBid2024'>
              <h1 className='whats-new-pdf-button-header mb-5 text-center'>2024</h1>
            </button>
          </div>

          <div className='public-bidding'>
            <button className='btn' type='button' data-toogle='collapse' data-target='#collapseBid2024003' aria-expanded='false' aria-controls='collapseBid2024'>
              <h6 className='text-heading history-title text-center'>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Procurement of Lot in Buenavista   
              </h6>
            </button>
            <div id='collapseBid2024003'>
              <div className='text-center mb-5'>

                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/Invitation&RFQ2024-018.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INVITATION & REQUEST FOR QUOTATION</a>
                </div>
              </div>
            </div>
          </div>    

          <div className='public-bidding'>
            <button className='btn' type='button' data-toogle='collapse' data-target='#collapseBid2024002' aria-expanded='false' aria-controls='collapseBid2024'>
              <h6 className='text-heading history-title'>
              Public Bidding for the Supply of Various Electrical Materials for Coop Use  
              </h6>
            </button>
            <div id='collapseBid2024002'>
              <div className='text-center mb-5'>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/invitation_to_pre-qualify_&_to_bid_2024-002.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INVITATION TO PRE-QUALIFY & TO BID</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/list_of_materials_2024-002.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>LIST OF MATERIALS</a>
                </div>
                {/* <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/specifications_2024-001.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>BOOM TRUCK SPECIFICATIONS</a>
                </div> */}
                {/* <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/Bid_Bulletin_2023-008-01.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>BID BULLETIN No. 2023-008-01 </a>
                </div> */}
              </div>
            </div>
          </div>       

          <div className='public-bidding'>
            <button className='btn' type='button' data-toogle='collapse' data-target='#collapseBid2024001' aria-expanded='false' aria-controls='collapseBid2024'>
              <h6 className='text-heading history-title'>
              Public Bidding for the Supply of Two (2) Units of Boom Truck for Coop Use  
              </h6>
            </button>
            <div id='collapseBid2024001'>
              <div className='text-center mb-5'>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/invitation_to_pre-qualify_&_to_bid_2024-001.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INVITATION TO PRE-QUALIFY & TO BID</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/specifications_2024-001.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>BOOM TRUCK SPECIFICATIONS</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/Bid Bulletin No. 2024-001-01.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>BID BULLETIN No. 2024-001-01 </a>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </section>
      <hr />

      <section className='contact-section area-padding'>
        <div className='container'>
          <div className='text-center mb-3'>
            <button className='btn' type='button' data-toggle='collapse' data-target='collapseBid2023' aria-expanded='false' aria-controls='collapseBid2023'>
              <h1 className='whats-new-pdf-button-header mb-5 text-center'>2023</h1>
            </button>
          </div>
          
        <div id='collapseBid2023' className='collapse show'>
          <div className='public-bidding'>
            <button className='btn' type='button' data-toogle='collapse' data-target='#collapseBid2023008' aria-expanded='false' aria-controls='collapseBid2023'>
              <h6 className='text-heading history-title'>
              Public Bidding for the Supply of Various Electrical Materials for 2023 Sitio Electrification Program (SEP)  
              </h6>
            </button>
            <div id='collapseBid2023008' className='collapse hide'>
              <div className='text-center mb-5'>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/invitation_to_pre-qualify_&_to_bid_2023-008.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INVITATION TO PRE-QUALIFY & TO BID</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/list_of_materials_2023-008.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>LIST OF MATERIALS</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/Bid_Bulletin_2023-008-01.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>BID BULLETIN No. 2023-008-01 </a>
                </div>
              </div>
            </div>
          </div>

          <div className='public-bidding'>
            <button className='btn' type='button' data-toogle='collapse' data-target='#collapseBid2023007' aria-expanded='false' aria-controls='collapseBid2023'>
              <h6 className='text-heading history-title'>
              Public Bidding for the Supply of Various Electrical Materials for Replenishment of Materials Used for Special Projects  
              </h6>
            </button>
            <div id='collapseBid2023007'>
              <div className='text-center mb-5'>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/invitation_to_pre-qualify_&_to_bid_2023-007.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INVITATION TO PRE-QUALIFY & TO BID</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/list_of_materials_2023-007.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>LIST OF MATERIALS</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/Bid_Bulletin_2023-007-01.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>BID BULLETIN No. 2023-007-01 </a>
                </div>
              </div>
            </div>
          </div>

          <div className='public-bidding'>
            <button className='btn' type='button' data-toogle='collapse' data-target='#collapseBid2023006' aria-expanded='false' aria-controls='collapseBid2023'>
              <h6 className='text-heading history-title'>
                Public Bidding for the Supply of Various Electrical Materials for Relocation of Poles affected by DPWH Projects  
              </h6>
            </button>
            <div id='collapseBid2023006'>
              <div className='text-center mb-5'>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/invitation_to_pre-qualify_&_to_bid_2023-006.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INVITATION TO PRE-QUALIFY & TO BID</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/list_of_materials_2023-006.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>LIST OF MATERIALS</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/Bid_Bulletin_2023-006-01.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>BID BULLETIN No. 2023-006-01 </a>
                </div>

              </div>
            </div>
          </div>


          <div className='public-bidding'>
            <button className='btn' type='button' data-toogle='collapse' data-target='#collapseBid2023005' aria-expanded='false' aria-controls='collapseBid2023'>
              <h6 className='text-heading history-title'>
              Public Bidding for the Supply of Various Materials for the Rehabilitation of Vee Phase Line, Transformer and Metering Installation at Globe, Lupac, Boac & Other Special Projects              
              </h6>
            </button>
            <div id='collapseBid2023005'>
              <div className='text-center mb-5'>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/invitation_to_pre-qualify_&_to_bid_2023-005.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INVITATION TO PRE-QUALIFY & TO BID</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/list_of_materials_2023-005.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>LIST OF MATERIALS</a>
                </div>
                {/* <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/instruction_to_bidders_2023-002.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INSTRUCTION TO BIDDERS</a>
                </div> */}
              </div>
            </div>
          </div>
          <div className='public-bidding'>
            <button className='btn' type='button' data-toogle='collapse' data-target='#collapseBid2023004' aria-expanded='false' aria-controls='collapseBid2023'>
              <h3 className='text-heading history-title'>
              Public Bidding for the Supply of Labor and Materials for the Construction of Line Extension and Housewiring Installation for 2023 Sitio Electrification Program (SEP)
              </h3>
            </button>
            <div id='collapseBid2023004'>
              <div className='text-center mb-5'>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/invitation_to_pre-qualify_&_to_bid_2023-004.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INVITATION TO PRE-QUALIFY & TO BID</a>
                </div>
             </div>
            </div>
          </div>
          <div className='public-bidding'>
            <button className='btn' type='button' data-toogle='collapse' data-target='#collapseBid2023003' aria-expanded='false' aria-controls='collapseBid2023'>
              <h3 className='text-heading history-title'>
                Public Bidding for the Supply of Various Electrical Materials for Coop Operation and Maintenance and 2023 Sitio Electrification Program (SEP)
              </h3>
            </button>
            <div id='collapseBid2023003'>
              <div className='text-center mb-5'>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/invitation_to_pre-qualify_&_to_bid_2023-003.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INVITATION TO PRE-QUALIFY & TO BID</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/list_of_materials_2023-003.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>LIST OF MATERIALS</a>
                </div>
                {/* <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/instruction_to_bidders_2023-002.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INSTRUCTION TO BIDDERS</a>
                </div> */}
              </div>
            </div>
          </div>
          <div className='public-bidding'>
            <button className='btn' type='button' data-toogle='collapse' data-target='#collapseBid2023002' aria-expanded='false' aria-controls='collapseBid2023'>
              <h3 className='text-heading history-title'>
                Public Bidding for the Supply of Line Hardwares and Miscellaneous Materials for Relocation of Poles affected by DPWH Projects (JC1 Batch 6) & Replenishment of Various Electrical Materials Used for the Repair and Rehabilitation of Distribution Lines damaged by Typhoon Paeng
              </h3>
            </button>
            <div id='collapseBid2023002'>
              <div className='text-center'>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/invitation_to_pre-qualify_&_to_bid_2023-002.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INVITATION TO PRE-QUALIFY & TO BID</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/instruction_to_bidders_2023-002.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INSTRUCTION TO BIDDERS</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <a href='/resources/files/whats_new/list_of_materials_2023-002.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>LIST OF MATERIALS</a>
                </div>
              </div>
            </div>
          </div>

          <button className='btn' type='button' data-toggle='collapse' data-target='#collapseBid2023001' aria-expanded='false' aria-controls='collapseBid2023'>
            <h3 className='text-heading history-title'>
              Public Bidding for Supply of Various Electrical Materials for Relocation of Poles affected by DPWH Projects
            </h3>
          </button>  
          <div id='collapseBid2023001'>
            <div className='text-center'>
              <div className='whats-new-pdf-button-container'>
                <a href='/resources/files/whats_new/invitation_to_bid_2023-001.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INVITATION TO PRE-QUALIFY & TO BID</a>
              </div>
              <div className='whats-new-pdf-button-container'>
                <a href='/resources/files/whats_new/instruction_to_bidders_2023-001.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>INSTRUCTION TO BIDDERS</a>
              </div>
              <div className='whats-new-pdf-button-container'>
                <a href='/resources/files/whats_new/list_of_materials_2023-001.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '5px 5px', fontSize: 14}} className='genric-btn info'>LIST OF MATERIALS</a>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
      <hr />

      <section className='contact-section area-padding'>
        <div className='container'>
          <div className='text-center'>
            <button className='btn' type='button' data-toggle='collapse' data-target='#collapseBid2022'  aria-expanded='false' aria-controls='collapseBid2022'>
              <h1 className='whats-new-pdf-button-header mb-3 text-center'>2022</h1>
            </button>
          </div>
          <div id='collapseBid2022' className='collapse hide' >
            <h3 className='text-heading history-title'>
                BID BULLETIN<br />
              <small>(2022)</small>
            </h3>
            <div className='text-center'>
              <div>
                <div className='whats-new-pdf-button-container'>
                  <div className='whats-new-pdf-button-header'>NO. 2022-001-01</div>
                  <a href='/resources/files/whats_new/Bid Bulletin No. 2022-001-01.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <div className='whats-new-pdf-button-header'>NO. 2022-001-02</div>
                  <a href='/resources/files/whats_new/Bid Bulletin No. 2022-001-02.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <div className='whats-new-pdf-button-header'>NO. 2022-002-01</div>
                  <a href='/resources/files/whats_new/Bid Bulletin No. 2022-002-01.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
                </div>
              </div>
              <div className='mt-5'>
                <div className='whats-new-pdf-button-container'>
                  <div className='whats-new-pdf-button-header'>NO. 2022-004-01</div>
                  <a href='/resources/files/whats_new/Bid Bulletin No. 2022-004-01.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
                </div>
                <div className='whats-new-pdf-button-container-split'>
                  <div className='whats-new-pdf-button-header'>NO. 2022-004-02</div>
                  <a href='/resources/files/whats_new/Bid Bulletin No. 2022-004-02.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
                  <div className='genric-btn info dropdown-btn' data-toggle='dropdown'><span className='fas fa-caret-down'></span></div>
                  <div className='dropdown-menu' role='menu'>
                    <span className='dropdown-label'>Attachments</span>
                <div className='dropdown-divider' />
                  <a className='dropdown-item' href='/resources/files/whats_new/Bid Bulletin No. 2022-004-02 - Attachment 1.docx' target='_blank' rel='noopener noreferrer'>1. Omnibus Sworn Statemen (docx)</a>
                  <a className='dropdown-item' href='/resources/files/whats_new/Bid Bulletin No. 2022-004-02 - Attachment 2.pdf' target='_blank' rel='noopener noreferrer'>2. Sample Proof of VAT Payments... (pdf)</a>
                  <a className='dropdown-item' href='/resources/files/whats_new/Bid Bulletin No. 2022-004-02 - Attachment 3.pdf' target='_blank' rel='noopener noreferrer'>3. Sample Tax Clearance Certificate (pdf)</a>
                  <a className='dropdown-item' href='/resources/files/whats_new/Bid Bulletin No. 2022-004-02 - Attachment 4.pdf' target='_blank' rel='noopener noreferrer'>4. Authority of Signatory (pdf)</a>
                  <a className='dropdown-item' href='/resources/files/whats_new/Bid Bulletin No. 2022-004-02 - Attachment 5.docx' target='_blank' rel='noopener noreferrer'>5. Notarized Statement on Warranty... (docx)</a>
                  <a className='dropdown-item' href='/resources/files/whats_new/Bid Bulletin No. 2022-004-02 - Attachment 6.xlsx' target='_blank' rel='noopener noreferrer'>6. Notarized Statement on Completion (xlsx)</a>
                  <a className='dropdown-item' href='/resources/files/whats_new/Bid Bulletin No. 2022-004-02 - Attachment 7.xlsx' target='_blank' rel='noopener noreferrer'>7. Detailed Technical Specifications (xlsx)</a>
                </div>
              </div>
              <div className='whats-new-pdf-button-container'>
                <div className='whats-new-pdf-button-header'>NO. 2022-004-03</div>
                <a href='/resources/files/whats_new/Bid Bulletin No. 2022-004-03.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
              </div>
            </div>
          </div>
          <br/><br/><br/><br/>

          <h3 className='text-heading history-title'>
            PUBLIC BIDDING 2022-004<br />
            <small>Proposed Building Design for the Two-Storey Office Building at Brgy. Maharlika-Poblacion, Sta. Cruz, Marinduque</small><br />
            <small>(2022)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/public_bidding_proposed_building_design.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br/><br/><br/><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            PROCUREMENT OF CLOSED CIRCUIT TELEVISION (CCTV) SYSTEM<br />
            <small>for MARELCO Establishments</small><br />
            <small>(2022)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/procurement_2022_001.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br/><br/><br/><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            INVITATION TO PRE-QUALIFY AND TO BID<br />
            <small>for the Supply of Labor and Materials for the Construction of Two-Storey Office Building at Brgy. Maharlika-Poblacion, Sta. Cruz, Marinduque</small><br />
            <small>(2022)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/invitation_to_bid_2022-004.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br/><br/><br/><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            INSTRUCTION TO BIDDERS<br />
            <small>Supply of Labor and Materials for the Construction of Two-Storey Office Building at Brgy. Maharlika-Poblacion, Sta. Cruz, Marinduque</small><br />
            <small>(2022)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/instruction_to_bidders_2022-004.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br/><br/><br/><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            INVITATION TO PRE-QUALIFY AND TO BID<br />
            <small>for the Supply of Minivan and Pickup Truck Service for Coop Use</small><br />
            <small>(2022)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/invitation_to_bid_2022-003.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br/><br/><br/><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            INSTRUCTION TO BIDDERS<br />
            <small>Supply of Various Electrical Materials for relocation of poles affected by the DPWH Project, DOE-DPWH Joint Circular 1 (JC1) Batch 5, and Supply of Book Truck and Other Service Vehicles for Coop Use</small><br />
            <small>(2022)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/instruction_to_bidders_2022-002.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br/><br/><br/><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            List of Materials for Public Bidding<br />
            <small>JC 1 Batch 5</small><br />
            <small>(2022)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/public_bidding_list_of_materials_2022-002.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br/><br/><br/><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            INVITATION TO PRE-QUALIFY AND TO BID<br />
            <small>for the Supply of Various Electrical Materials for the Relocation of Poles affected by DPWH Project (JC1 Batch 5) and Supply of Boom Truck &amp; Other Service Vehicles for Coop Use</small><br />
            <small>(2022)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/invitation_to_bid_2022-002.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br/><br/><br/><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            REVISED INSTRUCTION TO BIDDERS<br />
            <small>Supply of Various Electrical Materials for Coop Operation and Maintenance and Sitio Electrification Program (SEP) 2021</small><br />
            <small>(2022)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/instruction_to_bidders_2022-001.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br/><br/><br/><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            INSTRUCTION TO BIDDERS<br />
            <small>Supply of Various Electrical Materials for Coop Operation and Maintenance and Sitio Electrification Program (SEP)</small><br />
            <small>(2022)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/instruction_to_bidders_3.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br/><br/><br/><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            List of Materials for Public Bidding<br />
            <small>Note: Discrepancy on total ABC versus the amount stated on the advertisement is due to the changes in the quantity and unit cost of some materials.</small><br />
            <small>(2022)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/public_bidding_list_of_materials_5.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
         </div>
        </div>
      </section>
      <hr />
      <section className='contact-section area-padding'>
        <div className='container'>
          <div className='text-center'>
            <button className='btn' type='button' data-toggle='collapse' data-target='#collapseBid2021' aria-expanded='false' aria-controls='collapseBid2019'>
              <h1 className='whats-new-pdf-button-header mb-3 text-center'>2021</h1>
            </button>
          </div>
          <div id='collapseBid2021' className='collapse hide'>
          <h3 className='text-heading history-title'>
            BID BULLETIN<br />
            <small>(2021)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <div className='whats-new-pdf-button-header'>NO. 2021-004-001</div>
              <a href='/resources/files/whats_new/Bid Bulletin No. 2021-004-001.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br /><br /><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            List of Materials for Public Bidding<br />
            <small>for the Supply of Various Electrical Materials for Relocation of Poles affected by DPWH Projects and Other Special Projects</small><br />
            <small>(2021)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/public_bidding_list_of_materials_4.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br /><br /><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            INSTRUCTION TO BIDDERS<br />
            <small>Supply of Various Electrical Materials for the Relocation of Pole affected by DPWH Projects (JC2) and Other Special Projects</small><br />
            <small>(2021)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/instruction_to_bidders_2.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br /><br /><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            INVITATION TO PRE-QUALIFY AND TO BID<br />
            <small>for Relocation of Poles affected by DPWH Projects</small><br />
            <small>(2021)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/invitation_to_bid_5.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br /><br /><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            List of Materials for Public Bidding<br />
            <small>for the Relocation of Poles affected by DPWH Projects</small><br />
            <small>(2021)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/public_bidding_list_of_materials_3.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br /><br /><br/>
        {/* </div>
      </section> */}
      
      <h3 className='text-heading history-title'>
        2nd INVITATION TO PRE-QUALIFY AND TO BID<br />
        <small>(2021)</small>
      </h3>
      <div className='text-center'>
        <div className='whats-new-pdf-button-container'>
          <a href='/resources/files/whats_new/invitation_to_bid_4.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
        </div>
      </div>
      <br /><br /><br/>
      {/* <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            List of Materials for Public Bidding<br />
            <small>(BAC 2021-003)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='https://drive.google.com/drive/folders/1tR_MhyCYEZaETrZl41Po2CvTeeY7hBX-?usp=sharing' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>Google Drive Link</a>
            </div>
          </div>
          <br /><br /><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            INVITATION TO PRE-QUALIFY AND TO BID<br />
            <small>(2021)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/invitation_to_bid_3.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br /><br /><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            List of Materials for Public Bidding<br />
            <small>(2021)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/public_bidding_list_of_materials_2.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br /><br /><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            INVITATION TO PRE-QUALIFY AND TO BID<br />
            <small>(2021)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/invitation_to_bid_2.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br /><br /><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            BID BULLETIN<br />
            <small>(2021)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <div className='whats-new-pdf-button-header'>NO. 2021-002</div>
              <a href='/resources/files/whats_new/Bid Bulletin No. 2021-002.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          <br /><br /><br/>
        {/* </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <h3 className='text-heading history-title'>
            List of Materials for Public Bidding<br />
            <small>(2021)</small>
          </h3>
          <div className='text-center'>
            <div className='whats-new-pdf-button-container'>
              <a href='/resources/files/whats_new/public_bidding_list_of_materials.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
            </div>
          </div>
          </div>
        </div>
      </section>
      <hr />
      <section className='contact-section area-padding'>
        <div className='container'>
          <div className='text-center'>
            <button className='btn' type='button' data-toggle='collapse' data-target='#collapseBid2020'  aria-expanded='false' aria-controls='collapseBid2020'>
              <h1 className='whats-new-pdf-button-header mb-3 text-center'>2020</h1>
            </button>
          </div>
          <div id='collapseBid2020' className='collapse hide'>
            <h3 className='text-heading history-title'>
              COMPETITIVE SELECTION PROCESS (CSP) UPDATES<br />
              <small>(2020)</small>
              <br /><br />
              <small>
                The Marinduque Electric Cooperative, Inc. (MARELCO) informs its stakeholders and
                member-consumer-owners of the following Generation Companies who express their
                intent to participate on MARELCO’s Competitive Selection Process (CSP) for its New
                Power Provider (NPP), to wit:
              </small>
              <br /><br />
              <table style={{width: '100%', fontSize: 30, textAlign: 'center'}}>
                <tbody><tr>
                    <td>YH Green Energy Inc.</td>
                  </tr>
                  <tr>
                    <td>DMCI Power Corporation</td>
                  </tr>
                  <tr>
                    <td>Energreen Power Inter-Island Corporation</td>
                  </tr>
                  <tr>
                    <td>Solar Philippines Power Project Holdings</td>
                  </tr>
                  <tr>
                    <td>Occ. Mindoro Consolidated Power Corp.</td>
                  </tr>
                  <tr>
                    <td>Mindoro Grid Corporation</td>
                  </tr>
                  <tr>
                    <td>Sunwest Corporation</td>
                  </tr>
                  <tr>
                    <td>Vivant Integrated Diesel Corporation</td>
                  </tr>
                  <tr>
                    <td>SI Power Corporation</td>
                  </tr>
                  <tr>
                    <td>Kaltimex Rural Energy Corporation</td>
                  </tr>
                  <tr>
                    <td>PI Energy Inc.</td>
                  </tr>
                </tbody></table>
              <br />
              <small>
                Opening of bids is soon to be scheduled considering finalization of administrative
                arrangements affected by the COVID-19 pandemic.
              </small>
            </h3>
          </div>
        </div>
      </section>
        <hr />
      <section className='contact-section area-padding'>
        <div className='container'>
          <div id='accordionBid2019'>
            <div className='text-center'>
              <button className='btn' type='button' data-toggle='collapse' data-target='#collapseBid2019' data-parent='#accordionBid2019' aria-expanded='false' aria-controls='collapseBid2019'>
                <h1 className='whats-new-pdf-button-header mb-3 text-center'>2019</h1>
              </button>
            </div>
          {/* </div>
        </div>     
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'> */}
          <div id='collapseBid2019' className='collapse hide'> 
            <h3 className='text-heading history-title'>
              INVITATION TO BID<br />
              <small>(2019)</small>
              <br /><br />
              <small>
                Invitation to Bid for the Conduct of Competitive Selection Process (CSP) <br />
                by Marinduque Electric Cooperative, Inc. (MARELCO) <br />
                for its New Power Provider (NPP)
              </small>
            </h3>
            <div className='text-center'>
              <a href='/resources/files/whats_new/invitation_to_bid.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>Download PDF</a>
            </div>
            <br /> <br /> <br />
          {/* </div>
        </div>
      </section>
      
      <section className='contact-section area-padding'>
        <div className='container'>
            <div id='collapseBid2019'>   */}
              <h3 className='text-heading history-title'>
                BID BULLETIN<br />
                <small>(2019)</small>
              </h3>
              <div className='text-center'>
                <div className='whats-new-pdf-button-container'>
                  <div className='whats-new-pdf-button-header'>NO. 2019-001</div>
                  <a href='/resources/files/whats_new/Bid Bulletin No. 2019-001.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <div className='whats-new-pdf-button-header'>NO. 2019-002</div>
                  <a href='/resources/files/whats_new/Bid Bulletin No. 2019-002.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <div className='whats-new-pdf-button-header'>NO. 2019-003</div>
                  <a href='/resources/files/whats_new/Bid Bulletin No. 2019-003.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
                </div>
                <div className='whats-new-pdf-button-container'>
                  <div className='whats-new-pdf-button-header'>NO. 2019-004</div>
                  <a href='/resources/files/whats_new/Bid Bulletin No. 2019-004.pdf' target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className='genric-btn info'>DOWNLOAD PDF</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )

}

export default WhatsNew
