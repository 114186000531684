import React, { useEffect } from 'react'
import Assets from '../../assets/_index'

const JobVacancy = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | Job Vacancy'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    <>
        <section className='breadcrumb blog_bg'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                    <div className='breadcrumb_iner'>
                        <div className='breadcrumb_iner_item'>
                        <h2>Job Vacancy</h2>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='contact-section area-padding asset-section '>
        <div className='container '>
            <h1 className='pb-4'>Vacant Position</h1>
            <hr></hr>
            <div className='align-middle  animated  slideInUp delay-1s mt-1'>
            
            <div className='row'>
                <div className='col-md-3'></div>
                <div className='col-md-6'>
                    <img src={Assets.gmHiring} alt='vacant position'></img>
                </div>
                <div className='col-md-3'></div>
            </div>
          </div>
        </div>
      </section>
    </>
  )

}
export default JobVacancy 