import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
import Assets from '../assets/_index'

const Home = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | Home'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    <>
      <section className='banner_part'>
          <div className='container'>
              <div className='row align-content-center'>
                  <div className='col-lg-6'>
                  <div className='banner_text aling-items-center'>
                      <div className='banner_text_iner'>
                          <h5>Ihatub, Boac, Marinduque</h5>
                          <h2>Marinduque Electric Cooperative, Inc.</h2>
                        </div>
                      </div>
                    </div>
                  </div>
          </div>
          <a href='https://www.youtube.com/embed/ZkS3UV1w--I' className='popup-youtube video_popup'><span className='fa fa-play'></span></a>
      </section>

      <section className='contact-section area-padding asset-section '>
        <div className='container '>
          <div className='row align-middle mt-1'>
            <div className='col-md-6 assets-container order-md-2 animated  slideInUp delay-1s'>
              <img src={Assets.Power_rate} alt="Updated Power Rate"/>
              <a className='power-rate' href="/resources/files/rates/2024/0924.pdf" target="_blank">
                <p className="power-rate"><em>Detailed Information</em></p>
              </a>
            </div>
            <div className='col-md-6 assets-container order-md-2 animated slideInUp delay-2s'>
              <img src={Assets.GenBreakdown} alt="Updated Generation Charge Breakdown"/>
              <a className='power-rate' href="/resources/files/generation_charges/2024/0924.pdf" target="_blank">
                <p className="power-rate"><em>Detailed Information</em></p>
              </a>
            </div>
          </div>
        </div>
      </section>

       <section className='apartment_part'>
        <div className='container'>
          <div className='section_tittle text-left mb-5'>
            <h1>Land Procurement
              <p><a  href ='/resources/files/whats_new/Invitation&RFQ2024-018.pdf' target="blank"> ( Request for Quotation) </a></p>
             </h1>
          </div>
          <div className='row justify-content-between align-content-center'>

            <div className='col-md-2'> </div> 

            <div className='col-md-8 vacant-pos'>
              <img src = {Assets.landpurchase} alt='LAND PURCHASE Announcement'></img>
            </div>

            <div className='col-md-2'></div>
          </div>
        </div>
      </section> 

      <section className='apartment_part'>
        <div className='container'>
          <div className='section_tittle text-left mb-5'>
            <h1>We Are Hiring!</h1>
          </div>
          <div className='row justify-content-between align-content-center'>

            <div className='col-md-2'> </div> 

            <div className='col-md-8 vacant-pos'>
              <img src = {Assets.gmHiring} alt='Hirng Announcement'></img>
            </div>

            <div className='col-md-2'></div>
          </div>
        </div>
      </section>

      <section className='apartment_part'>
        <div className='container'>
                  <div className="section_tittle">
                    <h1>Lifeline Subsidy & Senior Citizen Discount</h1>
                  </div>
          <div className='row align-middle'>
            <div className='col-md-6 assets-container order-md-2'>
              {/* <img src={Assets.RA11552} alt="Updated Power Rate"/> */}
            {/* </div> */}
            {/* Adding Carousel Image */}
              <div id="carouselExampleInterval" class="carousel slide"  data-ride="carousel" autoplay>
                <div class="carousel-inner">
                  <div class="carousel-item active" data-interval="3000">
                    <img src={Assets.RA11552} className="d-block w-100" alt="..."/>
                  </div>
                  <div class="carousel-item" data-interval="3000">
                    <img src={Assets.pg1} className="d-block w-100" alt="..."/>
                  </div>
                  <div class="carousel-item" data-interval="5000">
                    <img src={Assets.pg2} className="d-block w-100" alt="..."/>
                  </div>
                  <div class="carousel-item" data-interval="5000">
                    <img src={Assets.pg3} className="d-block w-100" alt="..."/>
                  </div>
                  <div class="carousel-item" data-interval="5000">
                    <img src={Assets.pg4} className="d-block w-100" alt="..."/>
                  </div>
                  <div class="carousel-item" data-interval="5000">
                    <img src={Assets.pg5} className="d-block w-100" alt="..."/>
                  </div>
                  <div class="carousel-item" data-interval="5000">
                    <img src={Assets.pg6} className="d-block w-100" alt="..."/>
                  </div>
                  <div class="carousel-item" data-interval="5000">
                    <img src={Assets.pg7} className="d-block w-100" alt="..."/>
                  </div>
                  <div class="carousel-item" data-interval="5000">
                    <img src={Assets.pg8} className="d-block w-100" alt="..."/>
                  </div>
                  <div class="carousel-item" data-interval="5000">
                    <img src={Assets.pg9} className="d-block w-100" alt="..."/>
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleInterval" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleInterval" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
            <div className='col-md-6 order-md-2 animated slideInUp delay-2s'>
              <img src={Assets.Senior_Citizen} alt="Senior Citizen"/>
            </div>
          </div>
        </div>
      </section>

     <section className='apartment_part'>
      <div className='container'>
        <div className='row justify-content-between align-content-center'>
          <div className='col-md-8 col-lg-7'>
            <div className='section_tittle mb-3 mt-4 text-left'>
              <h1>Informational Videos</h1>
            </div>
          </div>
          
          <div className='row align-middle fadeIn Up daley-1s'>
            <div className='col-lg-4  mb-5'>
              <ReactPlayer
                className='react-player'
                url={Assets.Distribution_process} alt='Power Distribution Process'
                width='100%'
                height='100%'
                controls={true}
              />
              <div className='informational-videos mt-2'>Power Distribution Process &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <i class="fa fa-thumbs-up"></i>  &nbsp; &nbsp; &nbsp;
                <i class="fa fa-heart"></i>
              </div>
              
               
            </div>
            
            <div className='col-lg-4 md-2 mb-5'>
              <ReactPlayer
                className='react-player'
                url= {Assets.Distribution_system} alt='Distribution System'
                width='100%'
                height='100%'
                controls={true}
              />
              <div className='informational-videos mt-2'>Power Distribution System  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <i class="fa fa-thumbs-up"></i>  &nbsp; &nbsp; &nbsp;
                <i class="fa fa-heart"></i>
              </div>
            </div>
            <div className='col-lg-4 md-2 mb-5'>
            <ReactPlayer
                className='react-player'
                url={Assets.Power_outages} alt='Reason of Power Outages'
                width='100%'
                height='100%'
                controls={true}
              />
              <div className='informational-videos mt-2'>Reason of Power Outages &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <i class="fa fa-thumbs-up"></i>  &nbsp; &nbsp; &nbsp;
                <i class="fa fa-heart"></i>
              </div>
            </div>
            
          </div>

        </div>
      </div>
     </section>
        

      {/* <section className='apartment_part'>
        <div className='container'>
            <div className="row justify-content-between align-content-center">
              <div className="col-md-8 col-lg-7">
                <div className="section_tittle">
                    <h1>Informational Videos</h1>
                </div>
              </div>
            </div>
            <div className='row align-middle fadeInUp delay-1s'>
              <div className='col-md-6 safety-tips-container order-md-2'>
                <div className='safety-tips-title'>Power Distribution Process</div>
              </div>
                <div className='col-md-6'>
                  <ReactPlayer
                    className='react-player'
                    url={Assets.Distribution_process} alt='Power Distribution Process'
                    width='100%'
                    height='100%'
                    controls={true}
                  />
            </div>
          </div>
        </div>
      </section>

      <section className='contact-section area-padding'>
        <div className='container'>
          <div className='row align-middle fadeInUp delay-1s'>
            <div className='col-md-6 safety-tips-container'>
              <div className='safety-tips-title'>MARELCO Distribution System</div>
            </div>
            <div className='col-md-6'>
              <ReactPlayer
                className='react-player'
                url= {Assets.Distribution_system} alt='Distribution System'
                width='100%'
                height='100%'
                controls={true}
              />
            </div>
          </div>
        </div>
      </section>

      <section className='contact-section area-padding'>
        <div className='container'>
          <div className='row align-middle fadeInUp delay-1s'>
            <div className='col-md-6 safety-tips-container order-md-2'>
              <div className='safety-tips-title'>Reason of Power Outages</div>
            </div>
            <div className='col-md-6'>
              <ReactPlayer
                className='react-player'
                url={Assets.Power_outages} alt='Reason of Power Outages'
                width='100%'
                height='100%'
                controls={true}
              />
            </div>
          </div>
        </div>
      </section> */}

      <div className="apartment_part">
      <div className="container">
         <div className="row justify-content-between align-content-center">
            <div className="col-md-8 col-lg-7">
               <div className="section_tittle">
                  <h1>Accredited Payment Partners</h1>
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-md-4 col-lg-2 mb-5 mt-5">
               <div className="single_appartment_part">
                  <div className="appartment_img">
                      <img src={Assets.XenPay} alt='XenPay' />
                     <div className="single_appartment_text">
                        <p>The fastest way to pay your bills! A simple way to pay bills in the sari-sari store.</p>
                        <p>Find the nearest store based on your location <a href='http://116.93.120.117:8888/XenPayPortal/help-center;jsessionid=CDAD6A278E2C4DAC597D2EF5E33A0A0C' alt='XenPay Portal'>here.</a></p>
                        <br/><br/>
                     </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-2 mb-5 mt-5">
               <div className="single_appartment_part">
                  <div className="appartment_img">
                      <img src={Assets.Maya} alt='Maya' />
                     <div className="single_appartment_text">
                     <p>Download <a href='https://play.google.com/store/apps/details?id=com.paymaya&gl=US'> Maya App </a> and secure your own payment account.</p>
                     <p>Load up and pay your bills conveniently at home.</p><br/><br/>
                     </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-2 mb-5 mt-5">
               <div className="single_appartment_part">
                  <div className="appartment_img">
                      <img src={Assets.Bayad} alt='Bayad' />
                     <div className="single_appartment_text">
                     <p>Download <a href='https://play.google.com/store/apps/details?id=com.bayadcenter.bayad&pli=1'> Bayad App </a> and secure your own payment account.</p>
                     <p>Load up and pay your bills conveniently at home.</p>
                     <br/><br/>
                     </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-2 mb-5 mt-5">
               <div className="single_appartment_part">
                  <div className="appartment_img">
                  <img src={Assets.CardBank} alt='Card Bank' />
                     <div className="single_appartment_text">
                        <p><i className="fa fa-map-marker"></i> Isok I, Boac</p>
                        <p><i className="fa fa-map-marker"></i> Market Site, Mogpog</p>
                        <p><i className="fa fa-map-marker"></i> Maharlika, Sta. Cruz</p>
                        <p><i className="fa fa-map-marker"></i> Poblacion, Gasan</p>
                        <p><i className="fa fa-map-marker"></i> Poblacion,Buenavista</p>
                        <p><i className="fa fa-map-marker"></i> Poblacion, Torrijos</p><br/><br/>
                     </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-2 mb-5 mt-5">
               <div className="single_appartment_part">
                  <div className="appartment_img">
                      <img src={Assets.SimInsurance} alt='SIM Insurance' />
                     <div className="single_appartment_text">
                     <p><i className="fa fa-map-marker"></i> Boac</p>
                        <p><i className="fa fa-map-marker"></i> Mogpog</p>
                        <p><i className="fa fa-map-marker"></i> Sta. Cruz</p>
                        <p><i className="fa fa-map-marker"></i> Gasan</p>
                        <p><i className="fa fa-map-marker"></i> Buenavista</p>
                        <p><i className="fa fa-map-marker"></i> Torrijos</p><br/><br/>
                     </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-2 mb-5 mt-5">
                  <div className="single_appartment_part">
                     <div className="appartment_img">
                        <img src={Assets.RaquelPawnshop} alt='Raquel Pawnshop' />
                        <div className="single_appartment_text">
                           <p><i className="fa fa-map-marker"></i> Boac</p>
                              <p><i className="fa fa-map-marker"></i> Mogpog</p>
                              <p></p>
                              <p></p>
                              <p></p>
                              <p></p><br/>
                           </div>
                     </div>
                  </div>
              </div>
         </div>
      </div>
   </div>     
    </>
  )

}


export default Home
