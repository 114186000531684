import Swal from 'sweetalert2'


let getElement = {

  byName : ( element ) => {
      return document.getElementsByName( element )[0]
  },

  byID : ( element ) => {
      return document.getElementById( element )[0]
  },

  byClass : ( element ) => {
      return document.getElementsByClassName( element )[0]
  }

}


let storage = {

  get : ( name ) => {
      return JSON.parse( localStorage.getItem( name ) )
  },

  set : ( name, data ) => {
      localStorage.setItem( name, JSON.stringify( data ) )
  },

  clear : () => {
      localStorage.clear()
  }

}



let swal = {

    show : ( type, title, text, timer = 0 ) => {
        Swal.fire({
            title : title,
            text : text,
            icon : type,
            showConfirmButton : true,
            timer : timer,
            timerProgressBar : true
        })
    },

    close : () => {
        Swal.close()
    }

}



export {
  getElement,
  storage,
  swal
}