import React, { useEffect } from 'react'
const files = require( './_files.json' )


const PowerRates = () => {

  const monthName = [ 'MonthNames', 'January','February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]

  const PowerRates = ( props ) => {

    return files.PowerRates.map( ( powerRates, index ) => {
      return <div className='card' key={ powerRates[0] }>
              <div className='card-header' id='headingOne'>
                <h2 className='mb-0 text-center'>
                  <button className='btn power-rates-subtitle' type='button' data-toggle='collapse' data-target={`#collapse${ powerRates[0] }`} aria-expanded='false' aria-controls={`collapse${ powerRates[0] }`}>
                    Power Rates ({ powerRates[0] })
                  </button>
                </h2>
              </div>
              <div id={`collapse${ powerRates[0] }`} className={ `collapse${ ( ( index + 2 )  === powerRates.length ? ' show' : '' ) }` } aria-labelledby='headingOne' data-parent='#accordionPowerRates'>
                <div className='card-body'>
                  <div className='text-center power-rates-content pt-3'>
                    <div>
                      {
                        powerRates[1].slice( 0, 6 ).map( ( billMonth, index ) =>{
                          return <a href={`/resources/files/rates/${ powerRates[0] }/${ billMonth }.pdf`} target='_blank' rel='noopener noreferrer' className='genric-btn info mr-2' key={ billMonth }>{ monthName[ parseInt( billMonth.substring( 0, 2 ) ) ] }</a>
                        })
                      }
                    </div>
                    <div>
                      {
                        powerRates[1].slice( 6 ).map( ( billMonth, index ) =>{
                          return <a href={`/resources/files/rates/${ powerRates[0] }/${ billMonth }.pdf`} target='_blank' rel='noopener noreferrer' className='genric-btn info mr-2' key={ billMonth }>{ monthName[ parseInt( billMonth.substring( 0, 2 ) ) ] }</a>
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
    })

  }

  useEffect( () => {
    document.title = 'MARELCO Website | Power Rates'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    <>
      <section className='breadcrumb blog_bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='breadcrumb_iner'>
                <div className='breadcrumb_iner_item'>
                  <h2>Power Rates</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className='contact-section area-padding'>
        <div className='container'>
          <div className='accordion' id='accordionPowerRates'>
            <PowerRates />
          </div>
        </div>
      </section>
    </>
  )
  
}


export default PowerRates
