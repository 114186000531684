import React, { useEffect } from 'react'
import Assets from '../../assets/_index'


const TheManagement = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | Home'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    <>
      
        <section className='breadcrumb blog_bg'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='breadcrumb_iner'>
                  <div className='breadcrumb_iner_item'>
                    <h2>The Management</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className='apartment_part'> */}
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-xs-6 col-md-3'>
                <div className='single_appartment_part'>
                  <div className='managers-image'>
                    <img className='bg-primary' src={Assets.GM} alt='Manager' />
                    <div className='managers-image-content'>
                      <h4>Engr. Gaudencio M. Sol, Jr.</h4>
                      {/* <h4 classname='managers-name'>Full Name</h4> */}
                      <p>Acting General Manager</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
            <div className='row justify-content-center'>
              <div className='col-xs-6 col-md-3'>
                <div className='single_appartment_part'>
                  <div className='managers-image'>
                    <img src={Assets.FSDman} alt='Manager' />
                    <div className='managers-image-content'>
                      <h4>Beverly N. Bugarin</h4>
                      {/* <h4 classname='managers-name'>Full Name</h4> */}
                      <p>FSD Manager</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xs-6 col-md-3'>
                <div className='single_appartment_part'>
                  <div className='managers-image'>
                    <img src={Assets.ISDman} alt='Manager' />
                    <div className='managers-image-content'>
                      <h4>Jofel Joyce L. Lasic</h4>
                      {/* <h4 classname='managers-name'>Full Name</h4> */}
                      <p>ISD Manager</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xs-6 col-md-3'>
                <div className='single_appartment_part'>
                  <div className='managers-image'>
                    <img src={Assets.IADman} alt='Manager' />
                    <div className='managers-image-content'>
                      <h4>Janine L. Regencia, CPA</h4>
                      {/* <h4 classname='managers-name'>Full Name</h4> */}
                      <p>IAD Manager</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-xs-6 col-md-3'>
                <div className='single_appartment_part'>
                  <div className='managers-image'>
                    <img src={Assets.ASDman} alt='Manager' />
                    <div className='managers-image-content'>
                      <h4>Engr. Michel Vasquez</h4>
                      {/* <h4>Full Name</h4> */}
                      <p>ASD Manager</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xs-6 col-md-3'>
                <div className='single_appartment_part'>
                  <div className='managers-image'>
                    <img src={Assets.TSDman} alt='Manager' />
                    <div className='managers-image-content'>
                      <h4>Engr. Matthew Principe</h4>
                      {/* <h4>Full Name</h4> */}
                      <p>TSD Manager</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xs-6 col-md-3'>
                <div className='single_appartment_part'>
                  <div className='managers-image'>
                    <img src={Assets.CPDman} alt='Manager' />
                    <div className='managers-image-content'>
                      <h4>Engr. Michel H. Sajul</h4>
                      {/* <h4>Full Name</h4> */}
                      <p>CPD Manager</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}

    </>
  )

}


export default TheManagement
