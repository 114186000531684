import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
import Assets from '../../assets/_index'

const PMOS = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | Consumer Infos & Safety Tips'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    
    <>
      <section className='breadcrumb blog_bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='breadcrumb_iner'>
                <div className='breadcrumb_iner_item'>
                  <h2>Pre-Membership Orientation Seminar</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container-xxl contact-section p-5'>
        <div className='container-fluid'>
          <div className='row '>
            <div className='col-md-4 flex-column d-flex justify-content-center'>
           
              {/* <h4>Paano maging kasapi ng kooperatiba?</h4> */}
              {/* <h4> </h4> */}
              <p className='pmos-req p-2'>Schedule of Face-to-Face Pre-Membership Orientation Seminar</p>
              <table class="pmos table table-bordered p-5">
                <tr>
                  <td className='text-center p-1'><b>Time</b></td>
                  <td className='text-center p-1'><b>Day</b></td>
                  <td className='text-center p-1'><b>Area</b></td>
                </tr>
                <tr>
                  <td className='text-center p-1'><p>9:00 AM</p></td>
                  <td className='text-center p-1'><p>Tuesday</p></td>
                  <td className='text-center p-1'><p>Main Office</p></td>
                </tr>
                <tr>
                  <td className='text-center p-1'><p>9:00 AM</p></td>
                  <td className='text-center p-1'><p>Tuesday</p></td>
                  <td className='text-center p-1'><p>Buenavista Area Office</p></td>
                </tr>
                <tr>
                  <td className='text-center p-1'><p>9:00 AM</p></td>
                  <td className='text-center p-1'><p>Tuesday</p></td>
                  <td className='text-center p-1'><p>Gasan Area Office</p></td>
                </tr>
                <tr>
                  <td className='text-center p-1'><p>9:00 AM</p></td>
                  <td className='text-center p-1'><p>Tuesday</p></td>
                  <td className='text-center p-1'><p>Mogpog Area Office</p></td>
                </tr>
                <tr>
                  <td className='text-center p-1'><p>9:00 AM</p></td>
                  <td className='text-center p-1'><p>Tuesday</p></td>
                  <td className='text-center p-1'><p>Sta. Cruz Area Office</p></td>
                </tr>
                <tr>
                  <td className='text-center p-1'><p>9:00 AM</p></td>
                  <td className='text-center p-1'><p>Tuesday</p></td>
                  <td className='text-center p-1'><p>Torrijos Area Office</p></td>
                </tr>
              </table>

              <p className='pmos-req p-2'>Mga Requirement para maging kasapi</p>       
              <ol className='pmos pb-4'>
                <p><li >Recent ID Picture (1 pc. 2"x2")<br/> <p className='pl-3'>both husband and wife, if married</p></li></p>
                <p><li>Photocopy of 2 valid IDs</li></p>
                <p><li className='pmos'>Community Tax Certificate (Sedula)</li></p>
                <p><li className='pmos'>Barangay Clearance</li></p>
                <p><li className='pmos'>Marriage Contract (para sa may asawa)</li></p>
                {/* <p><li className='pmos'>Pahintulot ng may-ari ng lupang dadaanan ng SDW na pirmado ng dalawang official ng barangay bilang saksi</li></p>
                <p><li className='pmos'>Pahintulot ng may-ari ng lupang tinitirikan ng bahay na pirmado ng dalawang official ng barangay bilang saksi (kung nakikitirik lamang ng bahay)</li></p> */}
                <p><li className='pmos'>Pre-Membership Orientation Seminar</li></p>
              </ol>
            </div>
            
            <div className='col-lg-8 justify-content-center '>
              <p className='text-justify pmos-intro'>Magandang araw po. Naglunsad ang MARELCO ng Online Pre-Membership Orientation Seminar (PMOS) para sa mga nagnanais na maging kasapi ng MARELCO.
                Ang PMOS ay isa sa mga hinihingi na requirement sa mga bagong aplikante para maging kasapi at makapagrequest ng electric service connection. </p><br/>
              
                <div className='pmos-vid'>
                    <ReactPlayer 
                      className='react-player'
                      url={Assets.pmos_vid} alt='MARELCO PMOS' 
                      controls = {true}
                      width = '100%'
                      height= '100%'
                                       
                    />
              </div>
                          <br></br>
               
              {/* <i><p className=' text-justify font-weight-normal text-reset pmos-intro'>Natapos mo ba panoorin ang video? Maaari mo ng sagutan ang <a  href='https://forms.gle/6fTZpJFnjoPQURFeA' className='font-weight-bolder' target='_blank' rel='noopener noreferrer'>Assessment Test</a>.</p></i> */}
                 <i><p className=' text-justify font-weight-normal text-reset pmos-intro'>Natapos mo ba panoorin ang video? Maaari mo ng sagutan ang <a  href='#home' className='font-weight-bolder'  rel='noopener noreferrer'>Assessment Test</a>.</p></i>

            </div>
          </div>

          <br/>            
              {/* <h4><strong><i class="fa fa-question-circle" aria-hidden="true"></i> Frequently Ask Questions</strong></h4> */}
        </div>
      </section>
     
    </>
  )


}

export default PMOS
