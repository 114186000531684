import React, { useEffect } from 'react'

const DownloadableForms = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | Downloadable Forms'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    <>
      <section className="breadcrumb blog_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb_iner">
                <div className="breadcrumb_iner_item">
                  <h2>Downloadable Forms</h2>
                </div>
              </div>
            </div>s
          </div>
        </div>
      </section>

      <section className="container-history mt-5 ">
        <div className="container">
          <h3 className="text-heading downloadable-form-title">MEMBERSHIP APPLICATION & INFORMATION SHEET FORM</h3>
          <div className="text-center">
            <div className="downloadable-form-pdf-button-container">
              <a href="/resources/files/forms/form_01.pdf" target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className="genric-btn info">DOWNLOAD PDF <i className="fa fa-download" aria-hidden="false" /></a>
            </div>
          </div>
        </div>
      </section>
      <section className="container-history">
        <div className="container">
          <h3 className="text-heading downloadable-form-title">CHECKLIST OF REQUIREMENTS FOR REGULAR AND TEMPORARY CONNECTION</h3>
          <div className="text-center">
            <div className="downloadable-form-pdf-button-container">
              <a href="/resources/files/forms/checklist_of_requirements.pdf" target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className="genric-btn info">DOWNLOAD PDF <i className="fa fa-download" /></a>
            </div>
          </div>
        </div>
      </section>
      <section className="container-history">
        <div className="container">
          <h3 className="text-heading downloadable-form-title">APPLICATION FOR TEMPORARY CONNECTION</h3>
          <div className="text-center">
            <div className="downloadable-form-pdf-button-container">
              <a href="/resources/files/forms/form_08.pdf" target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className="genric-btn info">DOWNLOAD PDF <i className="fa fa-download"  /></a>
            </div>
          </div>
        </div>
      </section>
      <section className="container-history">
        <div className="container">
          <h3 className="text-heading downloadable-form-title">APPLICATION FORM FOR DEDICATED TRANSFORMER </h3>
          <div className="text-center">
            <div className="downloadable-form-pdf-button-container">
              <a href="/resources/files/forms/AFDT.pdf" target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className="genric-btn info">DOWNLOAD PDF <i className="fa fa-download"  /></a>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="container-history">
        <div className="container">
          <h3 className="text-heading downloadable-form-title">SERVICE REQUEST FORM</h3>
          <div className="text-center">
            <div className="downloadable-form-pdf-button-container">
              <a href="/resources/files/forms/form_03.pdf" target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className="genric-btn info">DOWNLOAD PDF <i className="fa fa-download"  /></a>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="container-history">
        <div className="container">
          <h3 className="text-heading downloadable-form-title">ELECTRICAL INSTALLATION INSPECTION </h3>
          <div className="text-center">
            <div className="downloadable-form-pdf-button-container">
              <a href="" target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className="genric-btn info">DOWNLOAD PDF <i className="fa fa-download" /></a>
              <a href="/resources/files/forms/form_04.pdf" target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className="genric-btn info">DOWNLOAD PDF <i className="fa fa-download" /></a>
            </div>
          </div>
        </div>
      </section> */}
      <section className="container-history">
        <div className="container">
          <h3 className="text-heading downloadable-form-title">OTHER CONSUMER's REQUESTS FORM</h3>
          <div className="text-center">
            <div className="downloadable-form-pdf-button-container">
              <a href="/resources/files/forms/form_09.pdf" target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className="genric-btn info">DOWNLOAD PDF <i className="fa fa-download"  /></a>
            </div>
          </div>
        </div>
      </section>
      <section className="container-history">
        <div className="container">
          <h3 className="text-heading downloadable-form-title">SENIOR CITIZEN DISCOUNT APPLICATION FORM</h3>
          <div className="text-center">
            <div className="downloadable-form-pdf-button-container">
              <a href="/resources/files/forms/SCDForm.pdf" target='_blank' rel='noopener noreferrer' style={{margin: '0 5px', fontSize: 14}} className="genric-btn info">DOWNLOAD PDF <i className="fa fa-download" aria-hidden="true" /></a>
            </div>
          </div>
        </div>
      </section>
    </>
  )

}

export default DownloadableForms
