import React, { useEffect } from 'react'


const MissionAndVision = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | Mission & Vision'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    <>
      <div>
        <section className="breadcrumb blog_bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb_iner">
                  <div className="breadcrumb_iner_item">
                    <h2>Mission &amp; Vision</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container-history">
          <div className="container box_1170">
            <h3 className="mission-vision-title">Our Mission</h3>
            <p className="mission-vision-content">
              To provide and ensure total quality electric service for stakeholders' delight.
            </p>
          </div>
        </section>
        <section className="container-history">
          <div className="container box_1170">
            <h3 className="mission-vision-title">Our Vision</h3>
            <p className="mission-vision-content">
              One of the finest Electric Utilities in the Country by Year 2030.
            </p>
          </div>
        </section>
      </div>

    </>
  )

}


export default MissionAndVision
