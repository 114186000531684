import React, { useEffect } from 'react'
// import Assets from '../../assets/_index'

const SeniorCitizenDiscountApplication = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | Senior Citizen Discount Application'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    <>
      <section className="breadcrumb blog_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb_iner">
                <div className="breadcrumb_iner_item">
                  <h2>Senior Citizen Discount Application</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-history">
        <div className="container box_1170">
          <div className="container-senior-citizen-application">
            <p className="senior-citizen-application-title">
              PROCEDURE ON AVAILMENT OF SENIOR CITIZEN DISCOUNT
            </p>
            <ol className="ordered-list senior-citizen-application-content">
              <li>
                Apply for Senior Citizen Discount at the MARELCO Main/Area Office where he/she resides using the 
                prescribed form <a href="/resources/files/forms/SCDForm.pdf" target='_blank' rel='noopener noreferrer'>( Application for SCD Form )</a>.
              </li>
              <li>
                Present/Submit the following requirements" Proof of Age and Citizenship. ANY of the following:
                <br />
                <ol className="ordered-list">
                  <li>Birth Certificate (certified true copy)</li>
                  <li>OSCA ID</li>
                  <li>Any government ID (SSS, GSIS, Postal, PRC)</li>
                  <li>Proof of Residence</li>
                  <li>Barangay Certificate</li>
                  <li>Notarized affidavit of two (2) disinterested persons</li>
                  <li>Proof of Billing</li>
                  <li>Copy of OEBR (Official Electric Bill Receipt) in the name of the Senior Citizen (Present original 
                    upon application)</li>
                  <li>Proof of authority (for representative only)</li>
                  <li>Valid ID of representative (for representative only)</li>
                  <li>Authorization Letter (for representative only)</li>
                </ol>
              </li>
              <li>
                Fill-up the <a href="/resources/files/forms/SCdiscount.pdf" target='_blank' rel='noopener noreferrer'>Application Form.</a>
              </li>
              <li>
                Receives the Acknowledgement Slip portion.
              </li>
            </ol>
            <br />
            <p className="senior-citizen-application-content">
              Note: To be included in the billing for the current month, applications must be accepted and processed 
              until the 10th day of the month. Applications shall be renewed annually.
            </p>
          </div>
        </div>
      </section>
      <hr></hr>
      {/* <section className="container-history">
        <div className="container box_1170">
          <div className="container-senior-citizen-application">
            <p id="lifeline-subsidy" className="senior-citizen-application-title">
              LIFELINE SUBSIDY
            </p>
            <div id="carouselExampleInterval" class="carousel slide"  data-ride="carousel" autoplay>
              <div class="carousel-inner"> */}
                {/* <div class="carousel-item active" data-interval="3000">
                  <img src={Assets.RA11552} className="d-block w-100" alt="..."/>
                </div> */}
                {/* <div class="carousel-item active" data-interval="3000">
                  <img src={Assets.pg1} className="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item" data-interval="5000">
                  <img src={Assets.pg2} className="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item" data-interval="5000">
                  <img src={Assets.pg3} className="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item" data-interval="5000">
                  <img src={Assets.pg4} className="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item" data-interval="5000">
                  <img src={Assets.pg5} className="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item" data-interval="5000">
                  <img src={Assets.pg6} className="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item" data-interval="5000">
                  <img src={Assets.pg7} className="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item" data-interval="5000">
                  <img src={Assets.pg8} className="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item" data-interval="5000">
                  <img src={Assets.pg9} className="d-block w-100" alt="..."/>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleInterval" role="button" data-slide="prev">
                  <span class="caroussel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleInterval" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>        
          </div>
        </div>
      </section> */}
    </>
  )

}

export default SeniorCitizenDiscountApplication
