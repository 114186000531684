import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
// import Assets from '../../assets/_index'

const SafetyTips = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | Consumer Infos & Safety Tips'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    
    <>
      <section className='breadcrumb blog_bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='breadcrumb_iner'>
                <div className='breadcrumb_iner_item'>
                  <h2>Consumer Infos &amp; Safety Tips</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='contact-section area-padding'>
        <div className='container'>
          <div className='row align-middle'>
            <div className='col-md-6 safety-tips-container order-md-2'>
              <div className='safety-tips-title'>MARELCO Distribution System</div>
            </div>
            <div className='col-md-6'>
              <ReactPlayer
                className='react-player'
                url='/resources/vids/distribution_system.mp4'
                width='100%'
                height='100%'
                controls={true}
              />
            </div>
          </div>
        </div>
      </section>

      <section className='contact-section area-padding'>
        <div className='container'>
          <div className='row align-middle'>
            <div className='col-md-6 safety-tips-container'>
              <div className='safety-tips-title'>MARELCO Operation</div>
            </div>
            <div className='col-md-6'>
              <ReactPlayer
                className='react-player'
                url='/resources/vids/marelco_operation.mp4'
                width='100%'
                height='100%'
                controls={true}
              />
            </div>
          </div>
        </div>
      </section>

      <section className='contact-section area-padding'>
        <div className='container'>
          <div className='row align-middle'>
            <div className='col-md-6 safety-tips-container order-md-2'>
              <div className='safety-tips-title'>Reason of Power Outages</div>
            </div>
            <div className='col-md-6'>
              <ReactPlayer
                className='react-player'
                url='/resources/vids/power_outages.mp4'
                width='100%'
                height='100%'
                controls={true}
              />
            </div>
          </div>
        </div>
      </section>

      <section className='contact-section area-padding'>
        <div className='container'>
          <div className='row align-middle'>
            <div className='col-md-6 safety-tips-container'>
              <div className='safety-tips-title'>Power Interruption Reports</div>
            </div>
            <div className='col-md-6'>
              <ReactPlayer
                className='react-player'
                url='/resources/vids/power_interruption_reports.mp4'
                width='100%'
                height='100%'
                controls={true}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className='contact-section area-padding'>
        <div className='container'>
          <div className='row align-middle'>
            <div className='col-md-12 safety-tips-container'>
              <img src={Assets.SafetyTips} alt="Safety Tips"/>
            </div>
          </div>
        </div>
      </section> */}
    </>
  )


}

export default SafetyTips
