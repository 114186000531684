import React, { useEffect, useState } from 'react'
import emailjs from '@emailjs/browser'
import LoadingOverlay from 'react-loading-overlay-ts'
import { swal } from '../globals/helpers'


const ContactUS = () => {

  const [ pageIsBusy, setPageIsBusy ] = useState( false )

  const [ formData, setFormData ] = useState({
    email : '',
    name : '',
    subject : '',
    message : ''
  })

  const [ formError, setFormError ] = useState({
    email : false,
    name : false,
    subject : false,
    message : false
  })

  useEffect( () => {
    document.title = 'MARELCO Website | Contact Us'
    document.body.removeAttribute( 'hidden' )
  }, [])

  const onChange = ( e ) => {
    setFormData( {
      ...formData,
      [ e.target.name ] : e.target.value
    })
    
    setFormError({
      ...formError,
      [ e.target.name ] : ( e.target.name === 'email' ? !e.target.value || ( !formData.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email) ) : !e.target.value )
    })
  }

  const onSend = () => {
    var isFormComplete = true

    setFormError({
      email : ( !formData.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email) ),
      name : ( !formData.name ),
      subject : ( !formData.subject ),
      message : ( !formData.message )
    })

    if (
      ( !formData.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email) ) ||
      !formData.name ||
      !formData.subject ||
      !formData.message
    ) { isFormComplete = false }
    

    if ( isFormComplete ) {
      setPageIsBusy( true )

      emailjs.send('service_b0szso7', 'template_8im9i7a', formData, 'Jp_G33ihh_1J3hCei').then( ( response ) => {
        //**test Email */
      // emailjs.send('service_du62yyh', 'template_6ov32l7', formData,'cfZQMkeV-kq_uiCDL').then( ( response ) => {
      //**test Email */ 
      setPageIsBusy( false )
        swal.show( 'success', 'Message Sent!', 'Thank you for reaching us. Your message has been successfully forwarded to our customer support team.' )
      }).catch( ( error ) => {
        setPageIsBusy( false )
        swal.show( 'error', 'Connection Timeout', 'An error occured while sending your message. Please try again later.' )
      })
    }
  }

  return (
    <>
      <section className='breadcrumb blog_bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='breadcrumb_iner'>
                <div className='breadcrumb_iner_item'>
                  <h2>Contact Us</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='contact-section area-padding container-contact-us'>
        <div className='container'>
          <div className='mapouter'>
            <div className='gmap_canvas'>
              {/* eslint-disable-next-line */}
              <iframe width='100%' height={500} id='gmap_canvas' src='https://maps.google.com/maps?q=marelco&t=&z=15&ie=UTF8&iwloc=&output=embed' frameBorder={0} scrolling='no' marginHeight={0} marginWidth={0} />
              <a href='https://www.crocothemes.net'> </a>
            </div>
            <style dangerouslySetInnerHTML={{__html: '\n          .mapouter { height: 500px; width: 100%; }\n          .gmap_canvas { overflow: hidden; background: none !important; height: 500px; width: 100% !important; }\n        ' }} />
          </div>
          <br />
          <br />
          <br />
          <LoadingOverlay
              active={ pageIsBusy }
              text="authenticating..."
          >
          <div className='row p-2'>
            <div className='col-12'>
              <h2 className='contact-title'>Get in Touch</h2>
            </div>
            <div className='col-lg-8'>
              <div className='form-contact contact_form'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <div className='form-group'>
                      <input className='form-control placeholder hide-on-focus' name='name' type='text' placeholder='Enter your name' onChange={ onChange } />
                      { ( formError.name ? <div className='text-danger text-right font-italic mt-1 mr-2'>Please enter your name*</div> : <div></div> ) }
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='form-group'>
                      <input className='form-control placeholder hide-on-focus' name='email' type='email' placeholder='Enter email address'onChange={ onChange } />
                      { ( formError.email ? <div className='text-danger text-right font-italic mt-1 mr-2'>Please enter a valid email*</div> : <div></div> ) }
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='form-group'>
                      <input className='form-control placeholder hide-on-focus' name='subject' type='text' placeholder='Enter Subject'onChange={ onChange } />
                      { ( formError.subject ? <div className='text-danger text-right font-italic mt-1 mr-2'>Please enter a subject*</div> : <div></div> ) }
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='form-group'>
                      <input className='form-control placeholder hide-on-focus' minLength='8' name='accountno' type='number' placeholder='Enter Account Number' onChange={ onChange } />
                      { ( formError.accountno ? <div className='text-danger text-right font-italic mt-1 mr-2'> Please enter account number* </div>:<div></div>)}
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='form-group'>
                      <textarea className='form-control w-100 placeholder hide-on-focus' name='message' cols={30} rows={9} placeholder='Enter Message'onChange={ onChange } />
                      { ( formError.message ? <div className='text-danger text-right font-italic mt-1 mr-2'>Please enter your message*</div> : <div></div> ) }
                    </div>
                  </div>
                </div>
                <div className='form-group mt-3'>
                  <button type='submit' onClick={ onSend } className='button button-contactForm'>Send Message</button>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='media contact-info'>
                <span className='contact-info__icon'><i className='fas fa-home' /></span>
                <div className='media-body'>
                  <h3><span>Marinduque Electric Cooperative, Inc.</span></h3>
                  <div className='mt-2'>Main Office</div>
                  <p>Ihatub, Boac, Marinduque</p>
                </div>
              </div>
              <div className='media contact-info'>
                <span className='contact-info__icon'><i className='fas fa-phone' /></span>
                <div className='media-body'>
                  <h3><span>Tel. No. (042) 332 2266</span></h3>
                  <h3><span>Mobile No. 0998 868 2476</span></h3>
                  <p>Mon to Fri 8am to 5pm</p>
                </div>
              </div>
              <div className='media contact-info'>
                <span className='contact-info__icon'><i className='fas fa-envelope' /></span>
                <div className='media-body'>
                  <h3><span>marelco_inc@yahoo.com</span></h3>
                  <p>Send us your query!</p>
                </div>
              </div>
            </div>
          </div>
          </LoadingOverlay>
        </div>
      </section>
    </>
  )

}


export default ContactUS
