import React from 'react'

const Header = () => {

  return (
    <header className='main_menu home_menu'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <nav className='navbar navbar-expand-lg navbar-light'>
              <a className='navbar-brand' href='/'> <img src='/resources/img/system/header_logo.png' alt='logo' /> </a>
              <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
                <span className='navbar-toggler-icon' />
              </button>
              <div className='collapse navbar-collapse main-menu-item' id='navbarNav'>
                <ul className='navbar-nav'>
                  <li className='nav-item active'>
                    <a className='nav-link' href='/'>Home<span className='sr-only'>(current)</span></a>
                  </li>
                  <li className='nav-item dropdown'>
                    <a className='nav-link dropdown-toggle' href='/' id='navbarDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                      About Us
                    </a>
                    <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                      <a className='dropdown-item' href='/about_us/history'>History</a>
                      <a className='dropdown-item' href='/about_us/mission_vision'>Mission &amp; Vision</a>
                      <a className='dropdown-item' href='/about_us/board_of_directors'>Board of Directors</a>
                      <a className='dropdown-item' href='/about_us/the_management'>The Management</a>
                    </div>
                  </li>
                  <li className='nav-item dropdown'>
                    <a className='nav-link dropdown-toggle' href='/' id='navbarDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                      News &amp; Updates
                    </a>
                    <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                      <a className='dropdown-item' href='/news_and_updates/whats_new'>What's New?</a>
                      <a className='dropdown-item' href='/news_and_updates/power_rates'>Power Rates</a>
                      <a className='dropdown-item' href='/news_and_updates/generation_charges'>Breakdown of Generation  Charges</a>
                      {/* <a className='dropdown-item' href='/news_and_updates/job_vacancy'>Job Vacancy</a> */}
                    </div>
                  </li>
                  <li className='nav-item dropdown'>
                    <a className='nav-link dropdown-toggle' href='/' id='navbarDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                      MCO's Information
                    </a>
                    <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                      <a className='dropdown-item' href='/members_consumers_owners/senior_citizen_discount_application'>Senior Citizen Discount</a>
                      <a className='dropdown-item' href='/members_consumers_owners/infos_and_safety_tips'>Consumer Infos &amp; Safety Tips</a>
                      <a className='dropdown-item' href='/members_consumers_owners/pmos'>Pre-Membership Orientation Seminar</a>
                      <a className='dropdown-item' href='/members_consumers_owners/electrical_connection_application'>Electrical Connection Application</a>
                      <a className='dropdown-item' href='/members_consumers_owners/downloadable_forms'>Downloadable Forms</a>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='/contact_us'>Contact us</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>

  )

}

export default Header
